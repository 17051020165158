import React from "react";
import { Navigate } from "react-router";

import DataUpload from "../pages/dataUpload";
import DataPreview from "../pages/dataPreview";
import Login from "../pages/login";
import Dashboard from "../pages/dashboard";
import Questions from "../pages/questionSteps";
import StorageCost from "../pages/storageCosts";
import OffloadingOptions from "../pages/offloadingOptions";
import AdditionalOptions from "../pages/offloadingOptions/AdditionalOptions";
import Register from "../pages/register";
import PreviousRecommendation from "../pages/previous-recomandation";
import ForgotPassword from "../pages/forgotPassword";
import ConfirmationPage from "../pages/confirmation";
import BuyerDataUpload from "../pages/buyerSearch";
import BuyerSearchResults from "../pages/buyerResults";
import Listing from "../pages/listing";
import Offer from "../pages/offer";
import SupplierUpload from "../pages/supplierUpload";
import TrackOffers from "../pages/trackOffers";
import ReviewOffer from "../pages/reviewOffer";
import OfferInfo from  "../pages/offerInfo";
import ReviewOfferInfo from "../pages/supplierNextSteps";
import ReviewOfferPaper from "../pages/supplierPaperwork";
import Notifications from "../pages/notifications";
import Tos from "../pages/tos";
import Settings from "../pages/settings";

// import Offer from "../pages/offer";

import {
  dashboardRoute,
  homeRoute,
  questionRoute,
  previousRecommendation,
  datapreviewRoute,
  dataUploadRoute,
  storageCostRoute,
  offloadingoptionRoutes,
  additionaloptionRoutes,
  RegisterRoute,
  forgotpasswordRoutes,
  confirmationRoutes,
  BusinessinfoRoute,
  dataprocessingRoutes,
  buyerDataUploadRoute,
  buyerSearchResultsRoute,
  listingRoute,
  offerRoute,
  supplierUploadRoute,
  trackOffersRoute,
  reviewOffersRoute,
  offerInfoRoute,
  reviewOffersInfoRoute,
  reviewOffersPaperRoute,
  notificationsRoute,
  tosRoute,
  settingRoute,
} from "./constants";
import BusinessInfo from "../pages/login/BusinessInfo";
import { Dataprocessing } from "../pages/confirmation/Dataprocessing";

var fData: any[] = [];
var mData: any[] = [];

const BuyerDataUploadWrapper: React.FC = () => {
  const updateFoundData = (data: any[]) => {
    fData = data;
  };

  const updateMissingData = (data: any[]) => {
    mData = data;
  };

  return (
    <BuyerDataUpload
      foundData={fData}
      missingData={mData}
      updateFoundData={updateFoundData}
      updateMissingData={updateMissingData}
    />
  );
};

const BuyerSearchResultsWrapper = () => {
  const updateFoundData = (data: any[]) => {
    fData = data;
  };

  const updateMissingData = (data: any[]) => {
    mData = data;
  };

  return (
    <BuyerSearchResults 
      foundData={fData}
      missingData={mData}
      updateFoundData={updateFoundData}
      updateMissingData={updateMissingData}
    />
  );
};

const authProtectedRoutes = [
  {
    path: homeRoute,
    exact: true,
    component: <Navigate to={supplierUploadRoute} />,
  },
  {
    path: dashboardRoute,
    component: <Dashboard />,
  },
  {
    path: dataprocessingRoutes,
    component: <Dataprocessing />,
  },
  {
    path: questionRoute,
    component: <Questions />,
  },
  {
    path: dataUploadRoute,
    component: <DataUpload />,
  },
  {
    path: buyerDataUploadRoute,
    component: <BuyerDataUploadWrapper/>,
  },
  {
    path: buyerSearchResultsRoute,
    component: <BuyerSearchResultsWrapper/>,
  },
  {
    path: supplierUploadRoute,
    component: <SupplierUpload/>
  },
  {
    path: previousRecommendation,
    component: <PreviousRecommendation />,
  },
  {
    path: datapreviewRoute,
    component: <DataPreview />,
  },
  {
    path: storageCostRoute,
    component: <StorageCost />,
  },
  {
    path: confirmationRoutes,
    component: <ConfirmationPage />,
  },
  {
    path: offloadingoptionRoutes,
    component: <OffloadingOptions />,
  },
  {
    path: additionaloptionRoutes,
    component: <AdditionalOptions />,
  },
  {
    path: trackOffersRoute,
    component: <TrackOffers />,
  },
  {
    path: notificationsRoute,
    component: <Notifications />,
  },
  {
    path: reviewOffersRoute,
    component: <ReviewOffer />,
  },
  {
    path: reviewOffersInfoRoute,
    component: <ReviewOfferInfo />,
  },
  {
    path: reviewOffersPaperRoute,
    component: <ReviewOfferPaper />,
  },
  { 
    path: settingRoute, 
    component: <Settings />, 
  },
];

const publicRoutes = [
  { path: homeRoute, component: <Login /> },
  { path: RegisterRoute, component: <Register /> },
  { path: BusinessinfoRoute, component: <BusinessInfo /> },
  {
    path: forgotpasswordRoutes,
    component: <ForgotPassword />,
  },
  { path: "*", component: <Navigate to={homeRoute} /> },
];

const accessibleRoutes = [
  { path: listingRoute, component: <Listing /> },
  { path: offerRoute, component: <Offer /> },
  { path: offerInfoRoute, component: <OfferInfo /> },
  { path: tosRoute, component: <Tos /> },
]

export { authProtectedRoutes, publicRoutes, accessibleRoutes };
