import styled from "@emotion/styled";
import { makeStyles } from "@material-ui/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Box, TextField } from "@mui/material";
import { Document } from 'react-pdf'

export const DataPreviewBox = styled("div", {
  shouldForwardProp: (prop) => prop !== "step",
})(
  ({ step }: { step: Number }) =>
    `max-width: 1020px;
width: 100%;
padding-top: ${step === 0 ? "65px" : "65px"};
margin: 0 auto;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
.question-list{
  minWidth: "367px"
}

`
);

/*
height:${step === 0 ? "577px" : step === 1 ? "688px" : "620px"} ; 
@media (max-width: 768px) {
  height:${step === 0 ? "1070px" : "690px"} ;
}
@media (max-height: 699px) {
  height:${step === 0 && "550px"} ;
}
@media  (max-width: 768px) and (max-height: 699px) { 
  height:${step === 0 ? "1070px" : "690px"} ;
}
  */

export const QuestionList = styled(Box, {
  shouldForwardProp: (prop) => prop !== "step",
})(
  ({ step }: { step: Number }) =>
    ` display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: ${step === 0 ? "920px" : "367px"};
    width: 100%;
   @media (max-width: 768px) {
   max-width: 100%;
}

`
);

export const RecomandationText = styled(Box)`
  text-align: center;
  font-size: 17px;
  margin: 0px;
  @media (max-width: 768px) {
    font-size: 15px;
  }
`;

export const DataPreviewText = styled("div")`
  color: #171717;
  font-size: 35px;
  font-family: "ProximaNova  !important";
  font-style: normal;
  font-weight: 700;
  padding-bottom: 5px;
  line-height: 124%;
  @media (max-width: 1280px) {
    font-size: 25px;
  }
`;

export const ContentText = styled("div")`
  color: #343434;
  font-size: 22px;
  margin-top: 28px;
  margin-bottom: 21.8px;
  font-style: normal;
  font-weight: 700;
  font-family: "ProximaNova-Medium  !important";
  line-height: 27px;
  @media (max-width: 1280px) {
    font-size: 20px;
  }
  @media (max-height: 699px) {
    margin-top: 20px;
    margin-bottom: 18px;
  }
`;

export const ProductTypeQuestion = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ProductTypeQuestionList = styled("div")`
  max-width: 280px;
  width: 100%;
  @media (max-width: 768px) {
    max-width: 685px;
  }
`;

export const StepperBox = styled("div")`
  max-width: 1022px;
  width: 100%;
`;
export const QuestionBox = styled(Box)`
  max-width: 1022px;
  width: 100%;
  height: 100%;
  padding-top: 82px;
  margin-bottom: 31px;
  margin: 0 auto;
  @media (max-height: 699px) {
    padding-top: 55px;
  }
`;

export const ButtonBox = styled("div", {
  shouldForwardProp: (prop) => prop !== "step",
})(
  ({ step }: { step: Number }) =>
    `max-width: ${step === 0 ? "920px" : "367px"};
    margin: 0 auto;
    padding-bottom: 6px;
    display: flex;
    flex-direction: row;
    margin-top: ${step === 0 && "0px"};
    justify-content:${step === 0 ? "end" : "space-between"} ;
    @media (max-width: 768px) {
      margin-top: 0px;
`
);

export const TextBox = styled("div")`
  margin-bottom: 10px;
  width: 100%;
`;

export const OptionSelectText = styled("div")`
  text-align: center;
  margin-bottom: 0.3rem;
  font-size: 16px;
`;

export const NextButton = styled("button")`
  background-color: #48b7a7;
  color: #fff;
  font-weight: 700;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 1px solid;
  padding: 15px 25px;
  border-radius: 5px;
  font-size: 22px;
  font-family: "ProximaNova  !important";
  gap: 10px;
  width: 100%;
  max-width: 175px;
  max-height: 55px;
  height: 100%;
  cursor: pointer;
  @media (max-width: 1280px) {
    font-size: 20px;
  }
  @media (max-height: 699px) {
    max-height: 50px;
  }
`;

export const BackButton = styled("button")`
  background-color: #ffffff;
  color: #48b7a7;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 1px solid #fff;
  padding: 15px 25px;
  border-radius: 5px;
  font-size: 22px;
  font-family: "ProximaNova  !important";
  gap: 10px;
  width: 100%;
  max-width: 100px;
  max-height: 55px;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
  @media (max-width: 1280px) {
    font-size: 20px;
  }
  @media (max-height: 699px) {
    max-height: 50px;
  }
`;
export const QontoConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 38,
    left: "calc(-50% + 2px)",
    right: "calc(50% + 2px)",
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "rgba(65, 193, 117, 0.42)",
    },
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "rgba(65, 193, 117, 0.42)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderTopWidth: 10,
    borderRadius: 1,
  },
}));

export const StyledTextField = styled(TextField)({
  width: "100%",
  height: "50px",
  border: "1px solid #41c175",
  "& .MuiInputBase-input": {
    fontSize: 22,
    height: "40px",
    textAlign: "center",
  },
});

export const ListStyle = makeStyles(() => ({
  list: {
    padding: "0px",
    width: "100%",
    fontSize: "22px ",
    fontWeight: 400,
    fontFamily: "ProximaNova-Semibold  !important",
    "@media(max-width:1280px)": {
      fontSize: "20px  !important",
    },
  },

  listItem: {
    color: "#41c175",
    height: "50px",
    backgroundColor: "#f4f4f4",
    marginBottom: "18px",
  },
  listItemSelected: {
    color: "#fff",
    height: "50px",
    backgroundColor: "#41c175",
    fontFamily: "ProximaNova-Semibold !important",
    marginBottom: "18px",
  },
  text: {
    color: "#41c175",
    cursor: "pointer",
    fontSize: "22px !important",
    textAlign: "center",
    fontFamily: "ProximaNova-Semibold !important",
    "@media(max-width:1280px)": {
      fontSize: "18px !important",
    },
  },
  textSelected: {
    color: "#fff",
    cursor: "pointer",
    fontSize: "22px !important",
    textAlign: "center",
    fontFamily: "ProximaNova-Semibold !important",
    "@media(max-width:1280px)": {
      fontSize: "18px !important",
    },
  },
}));


export const DocuBorder = styled(Document)`

`;

export const DocuDiv = styled('div')`
  width: 100%;
  margin-top: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PdfBox = styled("div")`
  max-height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const ContextHeading = styled("div")`
  margin-top: 102px;
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 32px 25px;
  border: solid 1px #e1e1e1;
  background-color: #fff;
  text-align: center;
}
`;

export const HeadingText = styled("div")`
  width: 500px;
  height: 42px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 24px;
  border-radius: 5px;
}
`;

export const ConfirmationText = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #171717;
  font-size: 25px;
  font-style: normal;
  font-family: "ProximaNova" !important;
  font-weight: 700;
  line-height: 124%;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const CheckboxLabel = styled.label`
  font-size: 16px;
  font-family: "ProximaNova", sans-serif;
`;
