import homeIcon from "../assets/images/svg/Home.svg";
import overviewIcon from "../assets/images/svg/Overview.svg";
import logoutIcon from "../assets/images/svg/Logout.svg";
import SettingsIcon from "../assets/images/svg/Settings.svg";
import NotificationIcon from "../assets/images/svg/Notification.svg";

import {
  logoutRoute,
  trackOffersRoute,
  supplierUploadRoute,
  notificationsRoute,
  settingRoute,
} from "../Routes/constants";

export const sideBarList = [
  {
    name: "Home",
    icon: <img src={homeIcon} alt="Home" />,
    url: supplierUploadRoute,
  },
  {
    name: "Track Offers",
    icon: <img src={overviewIcon} alt="track" />,
    url: trackOffersRoute,
  },
  {
    name: "Notifications",
    icon: <img src={NotificationIcon} alt="notification" />,
    url: notificationsRoute,
  },
];
  
export const setting = {
  name: "Settings",
  icon: <img src={SettingsIcon} alt="setting" />,
  url: settingRoute,
};

export const logout = {
  name: "Log out",
  icon: <img src={logoutIcon} alt="logout" />,
  url: logoutRoute,
};
